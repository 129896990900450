import { useEffect } from 'react'

import { fixedGetNodeIdCacheKey, useGetNodeIdMutation } from '@redux/features/routesPage/routesPage.api'

import { regExpressions } from '@utils/string/regularExpressions'

const useGetNodeId = (skip = false) => {
  const [postGetNodeId, { data: nodeIdResponse }] = useGetNodeIdMutation({
    fixedCacheKey: fixedGetNodeIdCacheKey,
  })

  useEffect(() => {
    !skip && !nodeIdResponse && void postGetNodeId(undefined)
  }, [nodeIdResponse, postGetNodeId, skip])

  return nodeIdResponse?.systemPath.replace(regExpressions.noDigits, '')
}

export default useGetNodeId
