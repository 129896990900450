import styled from 'styled-components'

import type { FlagIconProps } from './FlagIcon'

export const FlagStyled = styled.span.attrs<Omit<FlagIconProps, 'code'> & { $code: string; $flagDomain?: string }>(
  p => ({
    style: {
      height: p.height,
      width: p.width,
      ...p.styles,
    },
  })
)`
  display: block;
  background: url('${p => p.$flagDomain}/common/flags/${p => p.$code.toUpperCase()}.svg') no-repeat;
  border-radius: 2px;
`
