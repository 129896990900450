import { useEffect, useRef } from 'react'

const useScrollControl = (disabled = false) => {
  const safeDocument = typeof document !== 'undefined' ? document : {}
  const { body } = safeDocument as Document
  const html = (safeDocument as Document).documentElement
  const scrollPosition = useRef(0)

  const disableScroll = () => {
    const scrollBarWidth = window.innerWidth - (html?.clientWidth || 0)
    const bodyPaddingRight = parseInt(window.getComputedStyle(body).getPropertyValue('padding-right')) || 0
    body.style.paddingRight = `${bodyPaddingRight + scrollBarWidth}px`
    scrollPosition.current = document.documentElement.scrollTop
    body.style.position = 'fixed'
    body.style.top = `-${scrollPosition.current}px`
    body.style.width = '100%'
    body.style.scrollBehavior = 'auto'
    document.documentElement.style.scrollBehavior = 'auto'
  }

  const enableScroll = () => {
    body.style.position = ''
    body.style.top = ''
    body.style.width = ''
    body.style.scrollBehavior = ''
    body.style.paddingRight = ''
    window.scrollTo(0, scrollPosition.current)
    document.documentElement.style.scrollBehavior = ''
  }

  useEffect(() => {
    disabled ? disableScroll() : enableScroll()
  }, [disabled])
}

export default useScrollControl
